.c-group-button--right,
.c-group-button--left {
  display: inline-flex;
}

.c-group-button--right > button,
.c-group-button--right > div {
  margin-right: 20px;
}

.c-group-button--right > button:last-child,
.c-group-button--right > div:last-child {
  margin-right: 0;
}

.c-group-button--left > button,
.c-group-button--left > div {
  margin-left: 20px;
}

.c-group-button--left > button:last-child,
.c-group-button--left > div:last-child {
  margin-left: 0;
}
