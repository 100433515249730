@import url("https://fonts.googleapis.com/css?family=Open+Sans");

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}

@media screen and (max-width: 600px) {
  #root {
    /* padding-top: 60px; */
  }
}

body {
  background-color: #eee !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Open Sans"; */
}

.page-logo img {
  width: inherit !important;
  height: inherit !important;
}

.center {
  text-align: center;
}

.align-left {
  text-align: left !important;
}

.panel.panel-tab {
  border-radius: 0 4px 4px 4px;
}

.page-content > div {
  width: 100%;
  display: block;
}

.toast-error {
  background-color: #bd362f;
}

select:focus {
  background-color: transparent !important;
}

#contact-form select:focus,
#contact-form select,
#contact-form input {
  background-color: #fff !important;
}

#contact-form label {
  color: #fff;
}

.dx-row.dx-state-hover {
  cursor: pointer;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable,
.dx-scrollbar-horizontal .dx-scrollable-scroll {
  height: 16px !important;
}

.dx-scrollable-scroll-content {
  background-color: #7b7b7b !important;
  border-radius: 3px !important;
}

.page-logo .logo-min {
  display: none;
}

body.nav-function-minify .page-logo > a > .logo-min {
  display: block !important;
}

body.nav-function-minify .page-logo > a > .logo {
  display: none !important;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.grid-zindex-0 .dx-datagrid.dx-gridbase-container {
  z-index: 0;
}
